import React from 'react';
import ProjectOne from "../../elements/project/projectOne";


const Project = () => {
    return (
        <div className="rn-portfolio-area portfolio-style-1" id="portfolio">
            <div className="wrapper plr--45 plr_sm--15">
                <div className='projects-title'>
                    <h3>Projects</h3>
                </div>
                <ProjectOne />
            </div>
        </div>
    )
}
export default Project

import React from 'react';
 
const Skill = ( ) => {
    return (
        <div className="rn-about-area rn-section-gap line-separator" id="experience">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center mb--70">
                            {/* <span className="subtitle">SKILLS INFO</span> */}
                            <h3 className="title">My Experience</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 increased-spacing">
                        <div className="experience_inner">
                            <div className="single-list">
                                <div className="list_inner">
                                    <p><span>Jan 2022 - Present</span></p>
                                </div>
                                <div className="list_inner">
                                    <p><span>Senior Full Stack Developer</span></p>
                                </div>
                                <div className="list_inner">
                                    <p><span>Freelancing</span></p>
                                </div>
                            </div>

                            <div className="single-list">
                                <div className="list_inner">
                                    <p><span>Feb 2019 - Nov 2021</span></p>
                                </div>
                                <div className="list_inner">
                                    <p><span>Senior React/Laravel Developer</span></p>
                                </div>
                                <div className="list_inner">
                                    <p><span>Innosol Inc</span></p>
                                </div>
                            </div>

                            <div className="single-list">
                                <div className="list_inner">
                                    <p><span>Jun 2016 - Nov 2018</span></p>
                                </div>
                                <div className="list_inner">
                                    <p><span>React Native Developer</span></p>
                                </div>
                                <div className="list_inner">
                                    <p><span>VYNYL</span></p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Skill;

import React from "react"
import Modal from "react-bootstrap/Modal"
import { AiOutlineClose } from "react-icons/ai"
import { FiExternalLink } from "react-icons/fi"

const Projectpopup = ({
  show,
  setShow,
  projectImg,
  title,
  href,
  skills,
  detailed_description,
  imagesArray,
}) => {
  const first = imagesArray?.[0];
  const rest = imagesArray?.slice(1);
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      centered
      className="project-popup-main"
    >
      <div className="wrapper">
        <button className="close" onClick={() => setShow(false)}>
          <AiOutlineClose size={"20px"} color="black" />
        </button>
        {first ? null : <div className="thumbnail">{projectImg}</div>}
        {first}
        <div className="content-wrapper">
          {title && (
            <a className="link" href={href} target="_blank">
              <h5 className="title">{title}</h5>
              <FiExternalLink />
            </a>
          )}
          {skills && (
            <div className="technology">
              <b>Skills:</b> {skills}
            </div>
          )}
          {detailed_description && (
            <div className="description" dangerouslySetInnerHTML={{ __html: detailed_description }} />
          )}
          {rest}
        </div>
      </div>
    </Modal>
  )
}

export default Projectpopup

import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/homedefault/banner";
import Project from "../components/homedefault/project";
import Skill from "../components/homedefault/skill";
import Progressbar from "../elements/progressbar/progressbar";
import Contact from "../elements/contact/contact";

const IndexPage = () => (
  <Layout>
    <SEO title="Michael Vick" />
    <Banner />
    <Project />
    <Skill />
    <Progressbar />
    <Contact />
  </Layout>
  
)
export default IndexPage;
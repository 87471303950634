import React, { Component } from "react";
import { ProgressBar } from 'react-bootstrap';
import {useStaticQuery, graphql} from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

const ProgressOne = () => {
    const progressQueryData = useStaticQuery (graphql`
        query progressQuery {
            homedefaultJson(id: {eq: "progressbanner"}) {
                bgImage {
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                            presentationHeight
                            presentationWidth
                        }
                    }
                }
            }
        }
    `);
    const BgImageCover = progressQueryData.homedefaultJson.bgImage.childImageSharp.fluid;

    return (
        <div className="progress-bar-area" id="skillset">
            <div className="row row--0">
                <div className="col-lg-8 col-xl-6">
                    <div className="progressbar-inner progress-bar--1 bg-color-primary">
                        <div className="inner">
                            <div className="description">
                                <p>In a short time, I have been able to achieve excellence in all areas of development.</p>
                            </div>
                            <div className="single-progress">
                                <h6 className="title">React</h6>
                                <ProgressBar now={100} />
                                <span className="label">100%</span>
                            </div>

                            <div className="single-progress">
                                <h6 className="title">React Native</h6>
                                <ProgressBar now={100} />
                                <span className="label">100%</span>
                            </div>

                            <div className="single-progress">
                                <h6 className="title">Laravel</h6>
                                <ProgressBar now={100} />
                                <span className="label">100%</span>
                            </div>

                            <div className="single-progress">
                                <h6 className="title">Node</h6>
                                <ProgressBar now={100} />
                                <span className="label">100%</span>
                            </div>

                            <div className="single-progress">
                                <h6 className="title">Vue</h6>
                                <ProgressBar now={100} />
                                <span className="label">100%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-xl-6">
                    <BackgroundImage className="progress-bg-image" fluid={BgImageCover}>
                        <div className="inner-height"></div>
                    </BackgroundImage>
                </div>
            </div>
        </div>
    )
}

export default ProgressOne


import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Projectcard from "./projectcard";

const ProjectOne = () => {
    const ProjectData = useStaticQuery(graphql`
        query ProjectDataQuery {
            allProjectJson(limit: 9, sort: { fields: [order], order: ASC}) {
                edges {
                  node {
                    id
                    title
                    href
                    detailed_description
                    short_description
                    skills
                    featured_image {
                        childImageSharp {
                            fluid(quality: 100, maxWidth: 1920) {
                                ...GatsbyImageSharpFluid_withWebp
                                presentationWidth
                                presentationHeight
                            }
                        }
                    }
                    images {
                        publicURL
                        childImageSharp {
                            fluid(quality: 100, maxWidth: 1920) {
                                ...GatsbyImageSharpFluid_withWebp
                                presentationWidth
                                presentationHeight
                            }
                        }
                    }
                  }
                }
            }
        }
    `);

    const projectsData = ProjectData.allProjectJson.edges;
    return (
        <div className="row row--20 side-padding-20">
            {projectsData.map( data => (
                <Projectcard 
                    key={data.node.id}
                    column="col-xl-6 col-lg-6 col-12"
                    portfolioStyle="portfolio-style-1"
                    id={data.node.id}
                    image={data.node.featured_image.childImageSharp}
                    href={data.node.href}
                    title={data.node.title}
                    description={data.node.description}
                    technologies={data.node.technologies}
                    detailed_description={data.node.detailed_description}
                    skills={data.node.skills}
                    short_description={data.node.short_description}
                    images={data.node.images}
                />
            ))}
        </div>
    )
}

export default ProjectOne;
import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

const Banner = () => {
    const banenrQueryData = useStaticQuery (graphql`
        query BannerDefaultQuery {
                homedefaultJson(id: {eq: "main-banner"}) {
                title    
                subtitle
                description
                skill
                bgImage {
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 1920, maxHeight: 850) {
                            ...GatsbyImageSharpFluid_withWebp
                            presentationHeight
                            presentationWidth
                        }
                    }
                }
            },
            
            file(relativePath: {eq: "images/banner/person-image.jpg"}) {
                childImageSharp {
                  fixed (quality: 100, width: 500, height: 600) {
                    ...GatsbyImageSharpFixed
                  }
                }
            }
            
        }
    `);
      
    const BannerImages = banenrQueryData.homedefaultJson.bgImage.childImageSharp.fluid;
    const PortfolioImages = banenrQueryData.file.childImageSharp.fixed;
    const Title = banenrQueryData.homedefaultJson.title;
    const SubTitle = banenrQueryData.homedefaultJson.subtitle;
    const Description = banenrQueryData.homedefaultJson.description;
    const Skill = banenrQueryData.homedefaultJson.skill;
    return (
        <div className="rn-slider-area" id="home">
            {/* Start Single Slider  */}
            <div className="rn-slide slider-style-01 banner-fixed-height bg-color-white">
                <div className="wrapper">
                    <div className="custom-container">
                        <div className="row align-items-center">
                            {/* <div className="col-lg-5">
                                <div className="thumbnail">
                                    <Img className="portfolio-images" fixed={PortfolioImages} />
                                </div>  
                            </div> */}
                            <div className="col-lg-12">
                                <div className="inner">
                                    <div className="content text-left">
                                    <div className='banner-content'>
                                        <div className='title-container'>
                                            <span className="subtitle">{SubTitle}</span>
                                            <h1 className="title" dangerouslySetInnerHTML={{ __html: Title }}></h1>
                                            <h2 className="skill">{Skill}</h2>
                                        </div>
                                        <div className='description-container'>
                                            <p className="description" dangerouslySetInnerHTML={{ __html: Description }}></p>
                                        </div>
                                    </div>
                                        <a className="rn-button" href="Resume.pdf" target="_blank"><span>Download CV</span></a>
                                        <ul className="social-icons">
                                            <li><a href="https://www.linkedin.com/in/michael-vick-3a642a216/" target="_blank"><span>Linkedin</span></a></li>
                                            <li><a href="https://github.com/michaelvickdev" target="_blank"><span>Github</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
            {/* End Single Slider  */}
        </div>
    )
}
export default Banner;
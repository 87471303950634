import React, { useEffect, useMemo, useState } from 'react';
import Image from "../image";
import Img from 'gatsby-image'
import Projectpopup from './projectpopup';


const Projectcard = ({image, title, description, column, technologies, href, skills, detailed_description, short_description, images}) => {

    const [isMobile, setIsMobile] = useState(false);

    const imageStyles = useMemo(() => {
        return isMobile ? {

        } : {
            objectFit: "cover",
            objectPosition: "top"
        }
    }, [isMobile]);

    const onResize = () => {
        setIsMobile(window.innerWidth < 992);
    }

    useEffect(() => {
        window.addEventListener("resize", onResize);
        onResize();
        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, []);
    let projectImg;
    if(image.fixed && typeof image.fixed !== 'function'){
        projectImg = <Img 
            fixed={image.fixed} 
            alt={title} 
            imgStyle={{
                objectFit: "scale-down"
            }}/>;
    } else if(image.fluid){
        projectImg = <Image isMobile={isMobile} fluid={image.fluid} alt={title} imgStyle={{
            ...imageStyles,
        }}/>
    } else{
        projectImg = <img style={{ width: '100%', maxHeight: '406px' }} src={image.src || image.fluid.src} alt={title}/>
    }

    const imagesArray = useMemo(() => {
        if (!images?.length) {
            return null;
        }
        return images.map((image, index) => {
            let temproraryImage;
            if(image.fixed && typeof image.fixed !== 'function'){
                temproraryImage = <Img 
                    fixed={image.fixed} 
                    key={index}
                    alt={title} 
                    imgStyle={{
                        objectFit: "scale-down"
                    }}/>;
            } else if(image.fluid){
                temproraryImage = <Image key={index} isMobile={isMobile} fluid={image.fluid} alt={title} imgStyle={{
                    ...imageStyles,
                }}/>
            } else{
                temproraryImage = <img key={index} style={{ width: '100%', maxHeight: '406px' }} src={image.src || image?.fluid?.src || image.publicURL} alt={title}/>
            }
            return <div className="thumbnail spaced-from-top" key={index}>{temproraryImage}</div>;
        })
    }, [images, isMobile, imageStyles]);
    const [show, setShow] = React.useState(false);
    return (
        <div className={column}>
            <Projectpopup imagesArray={imagesArray} images={images} skills={skills} detailed_description={detailed_description} href={href} show={show} setShow={setShow} projectImg={projectImg} title={title} description={description} technologies={technologies} />
            <div className="portfolio" onClick={() => setShow(!show)}>
                <div target="_blank" rel="noreferrer">
                    <div className="thumbnail">
                            {projectImg}
                    </div>
                    <div className="content">
                        <div className="overlay">
                        <div className="inner">
                            {title && <h5 className="title">{title}</h5>}
                            {short_description || skills && <span className="description">{short_description || skills}</span>}
                        </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Projectcard;

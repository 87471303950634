import { graphql, useStaticQuery } from 'gatsby'
import React from 'react';
import Contactform from "./contactform";

const Contact = () => {
    const contactData = useStaticQuery(graphql`
        query contactDataQuery {
            homedefaultJson(id: {eq: "contactus"}) {
                title
                subtitle
            }
            site {
                siteMetadata {
                    getform_url
                }
            }
        }
    `);
    const Title = contactData.homedefaultJson.title;
    const Subtitle = contactData.homedefaultJson.subtitle;
    const { site: { siteMetadata: { getform_url } } } = contactData;
    return (
        <div className="rn-contact-area rn-section-gap bg-color-white" id="contact"> 
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center mb--30">
                            <span className="subtitle">{Subtitle}</span>
                            <h2 className="title" dangerouslySetInnerHTML={{ __html: Title }}></h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 col-12 mt--30">
                        <Contactform url={getform_url} />
                    </div>
                    {/* Start Contact Form  */}
                    <div className="col-lg-4 col-12 mt--30">
                        <div className="contact-info-address">
                            <div className="single-address">
                                <span className="subtitle">Email</span>
                                <p className="title"><a href="mailto:michael.vick.hello@gmail.com" className="title">michael.vick.hello@gmail.com</a></p>
                            </div>
                        </div>
                    </div>
                    {/* End Contact Form  */}
                </div>
            </div>
        </div>
    )
}

export default Contact
